import { call, put } from 'redux-saga/effects';
import { GET_AUDIT_LISTING_SUCCESS, GET_MULTIMODAL_TRACKING_DETAILS_SUCCESS, GET_TRIPS_SUCCESS, SET_API_LOG_TYPES, SET_BOOKING_ORDER_LOGS, SET_LOG_DETAILS, SET_MILESTONE_LOCATIONS_DROPDOWN, SET_SERVICE_TYPES_DROPDOWN, SET_TRIP_CONSIGNEE_DROPDOWN, SET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN, SET_TRIP_TRANSPORTERS_DROPDOWN, UPLOAD_DELAY_REASONS, SET_FAST_TAG_ROUTES, GET_CHANNEL_TYPE_SUCCESS } from 'Constants/actionConstants';
import {
    getDataWithToken,
    putDataWithToken,
    postDataWithToken,
    uploadFile,
    dowloadWithToken,
    getDataWithTokenforLocation,
    jsonToUrlParams,
    deleteDataWithToken,

    GET_TRIPS_URL,
    GET_TRIP_DETAIL_URL,
    SEARCH_DROPDOWN_URL,
    UPDATE_TRACKING_PREFERENCES_URL,
    GET_TRACKING_DETAIL_URL,
    GET_DETAILED_LOG_URL,
    DOWNLOAD_LOCATION_LOGS_URL,
    GET_LOCATIONS_URL,
    GET_DASHBOARD_URL,
    ADD_TRIP_URL,
    GET_DASHBOARD_DETAILS_URL,
    GET_MULTIPLE_TRIPS_URL,
    SEARCH_ON_MAP_URL,
    ADD_TRIP_TEMPLATE_URL,
    MANUAL_TRACK_URL,
    TRANSPORTER_DETAILS_URL,
    UPLOAD_TRIP_URL,
    PROVIDERS_SEARCH_URL,
    CANCEL_TRIP_URL,
    GPS_VENDORS_URL,
    HOLISTICS_URL,
    CURRENT_LOCATION_URL,
    TAT_URL,
    SHARE_TRIP_DETAILS_URL,
    ACTION_DETAILS_URL,
    EXCEPTION_ALERTS_URL,
    VEHICLE_TYPES_URL,
    VEHICLE_BODY_TYPES_URL,
    VEHICLE_DETAIL_OPTIONS_URL,
    VEHICLE_DATA_URL,
    SEEKERS_SEARCH_URL,
    GET_OPERATORS_TYPES_URL,
    GET_TRACKING_OPERATORS_URL,
    GET_HAWKEYE_FILTERS,
    CREATE_LOCATION_URL,
    GET_TRIP_STATUS_COUNT_URL,
    GET_DO_NO_BASED_ON_FROM_LOC_URL,
    GET_SCHEDULER_DASHBOARD_URL,
    GET_BRANCH_URL,
    GET_SCHEDULER_ROUTE_DETAILS_URL,
    FOIS_DOWNLOAD_TEMPLATE_URL,
    POST_FOIS_UPLOAD_URL,
    GET_FOIS_DATA_URL,
    GET_FOIS_TRACKING_URL,
    GET_FOIS_STATION_URL,
    POST_ADHOC_FNR_URL,
    GET_FOIS_DASHBOARD_URL,
    GET_FOIS_MOVEMENT_TYPE_URL,
    REMINDER_EMAIL_URL,
    DOWNLOAD_MILESTONE_TEMP_URL,
    UPLOAD_ORDERS_URL,
    GET_MILESTONE_OVERVIEW_DETAILS_URL,
    GET_MILESTONE_LR_COUNT_DETAILS_URL,
    GET_MILESTONE_ORDER_DETAILS_URL,
    GET_DOCKET_NUMBER_LIST_URL,
    GET_VEHICLE_REMARKS_URL,
    DOWNLOAD_LSP_TEMPLATE_URL,
    UPLOAD_LSP_URL,
    POD_UPLOAD_URL,
    GET_SERVICE_PROVIDERS_URL,
    UPDATE_EXCEPTION_MILESTONE,
    GET_MILESTONE_RESERVED_LR_LIST,
    CANCEL_EXCEPTION,
    MOVE_TO_EXCEPTION_MILESTONE_UPDATE,
    GET_REASONS,
    GET_AUDIT_LISTING_URL,
    GET_AUDIT_DETAILS_URL,
    COMPLETE_AUDIT_URL,
    GET_MOVEMENT_TYPE_URL,
    GET_TYPE_OF_SHORTAGES_URL,
    GET_VEHICLE_DETAILS_URL,
    GENERATE_DELAY_REASON_TEMPLATE_URL,
    UPLOAD_DELAY_REASONS_URL,
    GET_MULTIMODAL_TRACKING_DETAILS_URL,
    DOWNLOAD_EXCEL_URL,
    GET_MILESTONE_LOCATIONS_DROPDOWN_URL,
    GET_TRIP_CONSIGNEE_DROPDOWN_URL,
    GET_BOOKING_ORDER_LOGS_URL,
    GET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN_URL,
    GET_TRIP_TRANSPORTERS_DROPDOWN_URL,
    GET_API_LOG_TYPES_URL,
    GET_LOG_DETAILS_URL,
    GET_SERVICE_TYPES_DROPDOWN_URL,
    GET_AUDIT_LIST_COUNT_URL,
    baseUrl,
    GET_BOOKING_ORDER_FAILED_DOWNLOADS_URL,
    GET_FAST_TAG_ROUTES_URL,
    DOWNLOAD_FASTTAG_DETAILS_URL,
    // GET_BOOKING_ORDER_FAILED_DOWNLOADS_URL,
    GET_TRACKED_LOG_DETAILS_URL,
    GET_UNTRACKED_LOG_DETAILS_URL,
    GET_CHANNEL_TYPES_URL
} from 'Utils/api';

import Trip from 'Models/Trip';
import Tracking from 'Models/Tracking';
import Location from 'Models/Location';

import {
    GET_TRIP_DETAIL_SUCCESS,
    GET_TRANSPORTER_SEACH_SUCCESS,
    GET_ITEMS_SEACH_SUCCESS,
    GET_PLANT_SEACH_SUCCESS,
    GET_CUSTOMER_SEARCH_SUCCESS,

    CLEAR_TRIPS,
    CLEAR_TRIP_DETAIL,
    CLEAR_DETAILED_LOG,
    CLEAR_DASHBOARD,
    CLEAR_DASHBOARD_DETAILS,
    CLEAR_MULTIPLE_TRIPS,
    CLEAR_TRACKING_DETAIL,
    CLEAR_PROVIDERS,
    CLEAR_DOWNLOAD_LOG,
    DOWLOAD_LOCATION_LOGS_SUCCESS,

    GET_TRACKING_DETAIL_SUCCESS,
    GET_DETAILED_LOG_SUCCESS,
    GET_LOCATIONS_SUCCESS,

    GET_DASHBOARD_SUCCESS,
    GET_DASHBOARD_DETAILS_SUCCESS,

    GET_MULTIPLE_TRIPS_SUCCESS,

    SEARCH_ON_MAP_SUCCESS,

    GET_ADD_TRIP_TEMPLATE_SUCESS,

    POST_MANUAL_TRACKING_SUBMITTING,
    POST_MANUAL_TRACKING_SUCCESS,

    POST_TRANSPORTER_DETAILS_SUCCESS,
    POST_TRANSPORTER_DETAILS_SUBMITTING,

    ADD_TRIP_SUBMITTING,
    ADD_TRIP_SUCCESS,

    GET_PROVIDERS_SUCCESS,

    TOAST_ERROR,
    TOAST_SUCCESS,

    STORE_GPS_VENDORS_DETAILS,
    GET_HOLISTICS_SUBMITTING,
    GET_HOLISTICS_SUCCESS,

    STORE_CURRENT_LOCATION,
    STORE_SHARE_DETAILS,
    GET_TRIPS,

    STORE_ACTION_DETAILS,
    STORE_EXCEPTION_TRIP_DETAILS,
    CLEAR_EXCEPTION_TRIP_DETAILS,

    STORE_VEHICLE_TYPES,
    STORE_VEHICLE_BODY_TYPES,
    STORE_VEHICLE_DETAIL_OPTIONS,
    STORE_VEHICLE_DATA,

    CLEAR_SEEKERS,
    GET_SEEKER_SUCCESS,

    SAVE_OPERATOR_TYPE,
    NUMBERS_OPERATOR_TYPE,
    NUMBERS_OPERATOR_LOADING,
    SAVE_LOCATION_TYPE_FILTER,
    SAVE_TRIP_STATUS_COUNT,
    GET_SCHEDULER_DASHBOARD_SUCCESS,
    SAVE_BRANCH_TYPE_FILTER,
    SAVE_DI_TIME_FILTER,
    GET_SCHEDULER_ROUTE_SUCCESS,
    GET_FOIS_MOVEMENT_TYPE_SUCCESS,
    GET_REMINDER_EMAIL_SUCCESS,
    LOADING_START,
    GET_MILESTONE_OVERVIEW_SUCCESS,
    GET_MILESTONE_ORDER_SUCCESS,
    LOADING_END,
    GET_VEHICLE_REMARKS_SUCCESS,
    GET_SERVICE_PROVIDER_SUCCESS,
    GET_MILESTONE_RESERVED_LR_SUCCESS,
    // UPDATE_EXCEPTION_MILESTONE,
    GET_REASONS_SUCCESS,
    GET_MOVEMENT_TYPE_DATA,
    GET_SHORTAGE_TYPE_DATA,
    GET_VEHICLE_LIST,
    SET_FAST_TAG_LOGS
} from 'Constants/actionConstants';
import { STORE_TAT_DETAILS } from 'Constants/actionConstants';
import axios from 'axios'
import { getCompanySeekerId, getToken } from 'Utils/storage';

export function* getDetailedLog(action) {
    try {
        yield put({
            type: CLEAR_DETAILED_LOG
        });
        const data = yield call(getDataWithToken, GET_DETAILED_LOG_URL, {
            trip_id: action.tripId,
            from: action.startDate,
            to: action.endDate,
            search: action.search,
            tracking_type: action.trackingType,
            offset: action.offset,
            limit: action.limit || 10,
            order_by: action.order_by
        })
        if(data && data.data && data.data.locations) {
            const locations = (data.data.locations || []).map((loc)=> {
                return new Location(loc);
            });
            yield put({
                type: GET_DETAILED_LOG_SUCCESS,
                data: {
                    locations
                }
            });
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
        yield put({
            type: GET_DETAILED_LOG_SUCCESS,
            data: {
                locations:[]
            }
        });
        console.log('ERROR', e);
    }
}

export function* downloadLocationLogs(action) {
    try {
        yield put({ type: CLEAR_DOWNLOAD_LOG });
        const filename = `location_logs_${action.tripId}.xls`;
        const data = yield call(dowloadWithToken, DOWNLOAD_LOCATION_LOGS_URL, filename, {
            trip_id: action.tripId
        })
        if (action.success) {
            action.success(data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: 'Downloading Location Logs...'
        });
        yield put({
            type: DOWLOAD_LOCATION_LOGS_SUCCESS,
        });
    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getTrips(action) {
    try{
        const {
            interval,
            boundType,
            serviceType,
            limit,
            offset,
            transporterSearch,
            plantSearch,
            group_id,
            itemsSearch,
            search,
            type,
            bound_type,
            truck_location_status,
            sub_type,
            trip_mode,
            transport_type,
            location_type_status,
            provider_id,
            customerSearch,
            tracking_type,
            vendor_type,
            search_field,
            branch_ids,
            provider_ids,
            service_type,
            untracked_message,
          } = action.params;
        yield put({
            type: CLEAR_TRIPS
        });
        const data = yield call(getDataWithToken, GET_TRIPS_URL, {
            limit,
            offset,
            interval,
            bound: boundType,
            service_type: serviceType,
            // transporter_search:transporterSearch,
            plant_search: plantSearch && Object.keys(plantSearch) ? plantSearch.city : plantSearch,
            branch_id: plantSearch && Object.keys(plantSearch) ? plantSearch.branch_id : '',
            branch_ids,
            provider_ids,
            group_id: group_id || (plantSearch && Object.keys(plantSearch) ? plantSearch.group_id : ''),
            item_search: itemsSearch,
            customer_search: customerSearch,
            search,
            bound_type,
            type,
            type: truck_location_status,
            location_type_status: location_type_status,
            sub_type: sub_type || '',
            trip_mode,
            transport_type,
            provider_id,
            tracking_type,
            vendor_type,
            search_field,
            untracked_message
        });
        if(data && data.data && data.data.trips) {
            const tripData = data.data;
            const trips = tripData.trips.map((trip)=> {
                return new Trip(trip);
            });
            yield put({
                type: GET_TRIPS_SUCCESS,

                data: {
                    trips,
                    containerCount: tripData.container_count,
                    courierCount: tripData.courier_count,
                    ftlCount: tripData.ftl_count,
                    lclCount: tripData.lcl_count,
                    outboundCount: tripData.outbound_count,
                    inboundCount: tripData.inbound_count,
                    tripsCount: tripData.trips_count,
                    filterCount: tripData.filter_count,
                    actualData: tripData
                }
            });
        }
    } catch(e) {
        yield put({
            type: GET_TRIPS_SUCCESS,
            data: {
                trips:[]
            }
        });
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}
export function* getTripDetail(action) {
    try {
        yield put({
            type: CLEAR_TRIP_DETAIL
        });
        const data = yield call(getDataWithToken, GET_TRIP_DETAIL_URL, {
            trip_id: action.tripId
        })
        const tripDetail = new Trip(data.data);
        yield put({
            type: GET_TRIP_DETAIL_SUCCESS,
            data: {
                tripDetail
            }
        });
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}
export function* getTrackingDetails(action) {
    try {
        yield put({
            type: CLEAR_TRACKING_DETAIL
        })
        let param = {
            trip_id: action.tripId,
            tracking_type: action.trackingType,
            tracking_type: action.trackingType,
            seeker_id:action.seeker_id,
            p:action.p
        }
        // if(action.additionalparam) {
        //     const result = window.location.search.split('?')[1].split('=');
        //     param[result[0]] = result[1];
        // }
        const data = yield call(getDataWithToken, GET_TRACKING_DETAIL_URL, param)
        const trackingDetail = new Tracking(data.data);
        yield put({
            type: GET_TRACKING_DETAIL_SUCCESS,
            data: {
                trackingDetail
            }
        });
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        yield put({
            type: GET_TRACKING_DETAIL_SUCCESS,
            data: {
                trackingDetail: new Tracking({})
            }
        });
        console.log('ERROR', e);
    }
}
export function* searchOnMap(action) {
    try {
        const data = yield call(getDataWithToken, SEARCH_ON_MAP_URL, {
            trip_id: action.tripId,
            tracking_type: action.trackingType,
            search: action.search
        })
        const mapLocation = new Location(data.data.location);
        yield put({
            type: SEARCH_ON_MAP_SUCCESS,
            mapLocation
        });
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}
export function* searchTransporters(action) {
    try {
        const data = yield call(getDataWithToken, SEARCH_DROPDOWN_URL, {
            transporter_search: action.transporterSearch
        });
        // if(data && data.data && data.data.results) {
            yield put({
                type: GET_TRANSPORTER_SEACH_SUCCESS,
                data: data.data && data.data.results ? data.data.results : []
            })
        //}
    }catch(e) {
        yield put({
            type: GET_TRANSPORTER_SEACH_SUCCESS,
            data: []
        })
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}
export function* searchPlants(action) {
    try {
        let params = {};
        if(action.isAnalytics){
            params = {
                plant_search: action.plantSearch,
                include_consignees: action.includeConsignees,
                location_type: action.params.locationType ? (action.params.locationType || {}).id : undefined,
                bound: (action.params.boundType || {}).id,
                state: (action.params.stateName || {}).name,
                zone: (action.params.zone || {}).name,
                provider_id: action.params.provider_id,
            }
        } else {
            params = {
                plant_search: action.plantSearch,
                include_consignees: action.includeConsignees,
                provider_id: action.provider_id,
            }
        }
        const data = yield call(getDataWithToken, SEARCH_DROPDOWN_URL, params);
        // if(data && data.data && data.data.results) {
            yield put({
                type: GET_PLANT_SEACH_SUCCESS,
                data: data.data && data.data.results ? data.data.results : []
            })
        //}
    }catch(e) {
        yield put({
            type: GET_PLANT_SEACH_SUCCESS,
            data: []
        })
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}
export function* searchItems(action) {
    try {
        const data = yield call(getDataWithToken, SEARCH_DROPDOWN_URL, {
            item_search: action.itemsSearch
        });
        // if(data && data.data && data.data.results) {
            yield put({
                type: GET_ITEMS_SEACH_SUCCESS,
                data: data.data && data.data.results ? data.data.results : []
            })
        // }
    }catch(e) {
        yield put({
            type: GET_ITEMS_SEACH_SUCCESS,
            data: []
        })
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
} 
export function* searchCustomers(action) {
    try {
        const data = yield call(getDataWithToken, SEARCH_DROPDOWN_URL, {
            customer_search: action.customerSearch
        });
        // if(data && data.data && data.data.results) {
            yield put({
                type: GET_CUSTOMER_SEARCH_SUCCESS,
                data: data.data && data.data.results ? data.data.results : []
            })
        // }
    }catch(e) {
        yield put({
            type: GET_CUSTOMER_SEARCH_SUCCESS,
            data: []
        })
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}
export function* updateTrackingPreferences(action) {
    try {
        const data = yield call(putDataWithToken, UPDATE_TRACKING_PREFERENCES_URL, {
            trip_id: action.tripId,
            tracking_preference: action.trackingPreferences,
            notification_settings: action.notificationSettings
        });
        yield put({
            type: TOAST_SUCCESS,
            message: 'Successfully Updated'
        })
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}
export function* getLocations(action) {
    try {
        const data = yield call(getDataWithToken, GET_LOCATIONS_URL, action.param);
        if(data && data.data && data.data.results) {
            data.data.results.forEach((location)=> {
                //delete location.plant_name;
            })
            yield put({
                type: GET_LOCATIONS_SUCCESS,
                data: {
                    locations: data.data.results
                }
            });
        }
        if(action.onSuccess) {
            action.onSuccess(data.data.results);
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}
export function* getDashboard(action) {
    try {
        yield put({
            type: CLEAR_DASHBOARD
        });
        const {boundType, serviceType, transporterSearch, plantSearch, genericSearch, itemsSearch, trip_mode, transport_type } = action.params;
        const data = yield call(getDataWithToken, GET_DASHBOARD_URL, {
            trip_mode,
            transport_type,
            bound: boundType,
            service_type: serviceType,
            transporter_search:transporterSearch,
            plant_search: plantSearch,
            item_search: itemsSearch,
            // ttbil secondary dashboard filter params below
            source_location: genericSearch && genericSearch.source_location,
            src_group_id: genericSearch && genericSearch.src_group_id,
            dest_location: genericSearch && genericSearch.dest_location,
            dest_group_id: genericSearch && genericSearch.dest_group_id,
            status_text: genericSearch && genericSearch.status_text,
            to_date_search: genericSearch && genericSearch.to_date_search,
            from_date_search: genericSearch && genericSearch.from_date_search,
            di_time: genericSearch && genericSearch.di_time,
            search: genericSearch && genericSearch.search,
        });
        yield put({
            type: GET_DASHBOARD_SUCCESS,
            data: {
                dashboard: data.data,
            }
        });
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}
export function* getDashboardDetails(action) {
    try {
        const {boundType, serviceType, transporterSearch, plantSearch, itemsSearch, boundName, statusType} = action.params;
        yield put({
            type: CLEAR_DASHBOARD_DETAILS,
            data: {
                boundType: boundName,
            }
        });
        const data = yield call(getDataWithToken, GET_DASHBOARD_DETAILS_URL, {
            bound_type: boundType,
            type: statusType,
            service_type: serviceType,
            transporter_search:transporterSearch,
            plant_search: plantSearch,
            item_search: itemsSearch,
        });
        yield put({
            type: GET_DASHBOARD_DETAILS_SUCCESS,
            data: {
                boundType: boundName,
                trips: data.data.trips,
            }
        });
    }catch(e) {
        yield put({
            type: GET_DASHBOARD_DETAILS_SUCCESS,
            data: {
                boundType: '',
                trips: [],
            }
        });
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}
export function* addTrip(action) {
    try {
        yield put({
            type: ADD_TRIP_SUBMITTING
        });
        yield call(postDataWithToken, ADD_TRIP_URL , action.trip);
        yield put({
            type: ADD_TRIP_SUCCESS
        });
        yield put({
            type: STORE_TAT_DETAILS,
            data: '2 Days'
        });
        localStorage.setItem('teg-component', true);
        window.location.href = "/trips";
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
        yield put({
            type: ADD_TRIP_SUCCESS
        });
        console.log('ERROR', e);
    }
}
export function* getMultipleTrips(action) {
    try {
        yield put({
            type: CLEAR_MULTIPLE_TRIPS
        });
        const {boundType, serviceType, transporterSearch, plantSearch, itemsSearch, statusType, subType, trip_mode, location_type, transport_type, location_type_status, zone, state, clusters, provider_id} = action.params;
        const data = yield call(getDataWithToken, GET_MULTIPLE_TRIPS_URL, {
            bound: boundType ? boundType : undefined,
            service_type: serviceType ? serviceType : undefined,
            transporter_search:transporterSearch,
            provider_id:provider_id,
            plant_search: plantSearch && Object.keys(plantSearch) ? plantSearch.city : plantSearch,
            branch_id: plantSearch && Object.keys(plantSearch) ? plantSearch.branch_id : '',
            group_id: plantSearch && Object.keys(plantSearch) ? plantSearch.group_id : '',
            item_search: itemsSearch,
            type: statusType,
            sub_type: subType,
            trip_mode,
            location_type,
            transport_type,
            location_type_status,
            zone,
            state,
            clusters
        });
        yield put({
            type: GET_MULTIPLE_TRIPS_SUCCESS,
            data: {
                trips: data.data.trips,
                types: data.data.types,
                source_data: data.data.source_data,
                destination_data: data.data.destination_data,
            }
        });
    }catch(e) {
        yield put({
            type: GET_MULTIPLE_TRIPS_SUCCESS,
            data: {
                trips: [],
                types: [],
                source_data: [],
                destination_data: [],
            }
        });
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}

export function* getAddTripTemplate(action) {
    try{

        const data = yield call(getDataWithToken, ADD_TRIP_TEMPLATE_URL, {});
        yield put({
            type: GET_ADD_TRIP_TEMPLATE_SUCESS,
            data: {
                templateLink: data.data.link
            }
        });
    } catch(e) {
    }
}

export function* downloadAddTripTemplate(action) {
    try {
        yield put({
            type: ADD_TRIP_SUBMITTING
        });
        const filename = `add_trips_template.xlsx`;
        const data = yield call(dowloadWithToken, ADD_TRIP_TEMPLATE_URL, filename, {trip_mode: action.tripMode})
        if (action.success) {
            action.success(data);
        }
        yield put({
            type: ADD_TRIP_SUCCESS
        });
        yield put({
            type: TOAST_SUCCESS,
            message: 'Downloading Template...'
        });
        yield put({
            type: DOWLOAD_LOCATION_LOGS_SUCCESS,
        });
    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
        yield put({
            type: ADD_TRIP_SUCCESS
        });
    }
}

export function* uploadTrips(action) {
    try{
        const data = yield call(uploadFile, UPLOAD_TRIP_URL, action.file, action.params);
        yield put({
            type: GET_ADD_TRIP_TEMPLATE_SUCESS,
            data: {
                templateLink: ''
            }
        });
        if (data.status == 200) {
            yield put({
                type: TOAST_SUCCESS,
                message: data.message || "Uploaded The Trips Successfully"
            })
            setTimeout(()=> {
                localStorage.setItem('teg-component', true);
                location.href = "/trips";
            }, 1000)
        } else {
            yield put({
                type: TOAST_ERROR,
                message: data.message || "Option Unavailable"
            })
        }

    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* postManuatlTracking(action) {
    try{
        yield put({
            type: POST_MANUAL_TRACKING_SUBMITTING
        })
        const data = yield call(postDataWithToken, MANUAL_TRACK_URL, action.data);
        yield put({
            type: POST_MANUAL_TRACKING_SUCCESS
        })
        yield put({
            type: TOAST_SUCCESS,
            message: 'Tracking Details Successfully updated'
        })

    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
        yield put({
            type: POST_MANUAL_TRACKING_SUCCESS
        });
        console.log('ERROR', e);
    }
}
export function* postTransporterDetails(action) {
    try{
        yield put({
            type: POST_TRANSPORTER_DETAILS_SUBMITTING
        })
        const data = yield call(putDataWithToken, TRANSPORTER_DETAILS_URL, action.data);
        yield put({
            type: POST_TRANSPORTER_DETAILS_SUCCESS
        })
        yield put({
            type: TOAST_SUCCESS,
            message: 'Transporter Details Successfully updated'
        })
        if(action.onSuccess) {
            action.onSuccess();
        } else {

            yield call(getTripDetail, {
                tripId: action.data.trip_id
            })
        }
    } catch(e) {
        yield call(getTripDetail, {
            tripId: action.data.trip_id
        })
        yield put({
            type: POST_TRANSPORTER_DETAILS_SUCCESS
        })
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });


        console.log('ERROR', e);
    }
}
export function* getProviders(action) {
    try{
        yield put({
            type: CLEAR_PROVIDERS
        })
        const data = yield call(getDataWithToken, PROVIDERS_SEARCH_URL, {search: action.search});
        yield put({
            type: GET_PROVIDERS_SUCCESS,
            data: {
                providers: data.data.results
            }
        })
    } catch(e) {
        console.log('ERROR', e);
    }
}

export function* updateTripCancellation(action) {
    try {
        yield put({
            type: POST_MANUAL_TRACKING_SUBMITTING
        })
        const data = yield call(postDataWithToken, CANCEL_TRIP_URL, action.data);
        yield put({
            type: POST_TRANSPORTER_DETAILS_SUCCESS
        })
        if(action.onSuccess) {
            action.onSuccess();
        }
        yield put({
            type: TOAST_SUCCESS,
            message: data.message || 'Trip cancelled Successfully.'
        })

    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}

export function* getGPSVendors(action) {
    try {
        const data = yield call(getDataWithToken, GPS_VENDORS_URL, {seeker_id: action.seekerId});
        yield put({
            type: STORE_GPS_VENDORS_DETAILS,
            data: data.data.results
        })
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        console.log('ERROR', e);
    }
}

export function* getHolistics(action) {
    try{
        const data = yield call(getDataWithToken, HOLISTICS_URL, action.data);
        yield put({
            type: GET_HOLISTICS_SUBMITTING
        })
        yield put({
            type: GET_HOLISTICS_SUCCESS,
            token: data.data.token,
        })
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getCurrentLocation(action) {
    try{
        const data = yield call(getDataWithTokenforLocation, CURRENT_LOCATION_URL, action.data);
        yield put({
            type: STORE_CURRENT_LOCATION,
            data: Object.keys(data.data.location_data).length ? data.data.location_data[action.data.vehicle_no] : {}
        })
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getTATDetails(action) {
    try{
        const data = yield call(postDataWithToken,TAT_URL , action.data);
        yield put({
            type: STORE_TAT_DETAILS,
            data: data.data.tat || 0,
            notification_settings: data.data.notification_settings || [],
        })
        if(action.onSuccess) {
            action.onSuccess(data.data || {})
        }
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getSharedDetails(action) {
    try{
        const data = yield call(getDataWithToken, SHARE_TRIP_DETAILS_URL , action.data);
        yield put({
            type: STORE_SHARE_DETAILS,
            data: data.data
        })
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* postSharedDetails(action) {
    try{
        const data = yield call(postDataWithToken, SHARE_TRIP_DETAILS_URL , action.data);
        yield put({
            type: TOAST_SUCCESS,
            message: "Shared the Trip Details"
        })
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* confirmAtOrigin(action) {
    try{
        yield put({
            type: CLEAR_TRIPS
        });
        const data = yield call(putDataWithToken, UPDATE_TRACKING_PREFERENCES_URL, action.data);
        yield put({
            type: TOAST_SUCCESS,
            message: action.data.type === 4 ? "Confirmed Vehicle is at origin" : "Confirmed Vehicle Gate Out"
        })
        yield put({
            type: GET_TRIPS,
            params: action.params
        })
        if (action.onSuccess) {
          action.onSuccess(data.data || {});
        }
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getActionDetails(action) {
    try {
        yield put({ type: CLEAR_DASHBOARD });
        const data = yield call(getDataWithToken, ACTION_DETAILS_URL, action.data);
        yield put({
            type: STORE_ACTION_DETAILS,
            data: data.data || {}
        })
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getExceptionTripDetails(action) {
    try {
        const filename = 'In-Transit Details.xlsx';
        let data = [];
        yield put({ type: CLEAR_EXCEPTION_TRIP_DETAILS });
        if(action.data.in_transit_check){
            data = yield call(dowloadWithToken, EXCEPTION_ALERTS_URL, filename, action.data);
        } else {
            data = yield call(getDataWithToken, EXCEPTION_ALERTS_URL, action.data);
        }
        yield put({
            type: STORE_EXCEPTION_TRIP_DETAILS,
            data: data.data && data.data.trips ? data.data.trips : []
        })
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getVehiclesList(action) {
    try {
        const data = yield call(getDataWithToken, VEHICLE_TYPES_URL);
        yield put({
            type: STORE_VEHICLE_TYPES,
            data: data.data && data.data.list ? data.data.list : []
        })
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getVehicleBodyTypes(action) {
    try {
        const data = yield call(getDataWithToken, VEHICLE_BODY_TYPES_URL);
        yield put({
            type: STORE_VEHICLE_BODY_TYPES,
            data: (data.data && data.data.list) || []
        })
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getVehicleDetailOptions(action) {
    try {
        const data = yield call(getDataWithToken, VEHICLE_DETAIL_OPTIONS_URL, action.params || {});
        yield put({
            type: STORE_VEHICLE_DETAIL_OPTIONS,
            vehicleServiceTypes: (data.data && data.data.service_types) || [],
            vehicleTypes: (data.data && data.data.vehicle_types) || [],
            bodyTypes: (data.data && data.data.body_types) || []
        })
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getVehicleData(action) {
    try {
        const data = yield call(
            getDataWithToken,
            VEHICLE_DATA_URL,
            action.params
        );
        yield put({
            type: STORE_VEHICLE_DATA,
            data: data.data || {}
        })
        if (action.onSuccess) {
            action.onSuccess(data.data || {});
        }
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        yield put({
            type: STORE_VEHICLE_DATA,
            data: (e && e.data) || {}
        })
        if (action.onSuccess) {
            action.onSuccess({});
        }
    }
}

export function* postVehicleData(action) {
    try{
        const data = yield call(postDataWithToken, VEHICLE_DATA_URL , action.payload);
    } catch(e) {
        console.log('ERROR', e);
    }
}

export function* getSeekers(action) {
    try{
        yield put({
            type: CLEAR_SEEKERS
        })
        const data = yield call(getDataWithToken, SEEKERS_SEARCH_URL, {search: action.search});
        yield put({
            type: GET_SEEKER_SUCCESS,
            data: {
                seekers: data.data.results
            }
        })
        if (action.onSuccess) {
            action.onSuccess(data.data.results);
        }
    } catch(e) {
        console.log('ERROR', e);
    }
}

export function* getOpertorTypes(action) {
    try{
        const data = yield call(getDataWithToken, GET_OPERATORS_TYPES_URL);
        yield put({
            type: SAVE_OPERATOR_TYPE,
            data: data.data && data.data.results ? data.data.results : []
        })
    } catch(e) {
        console.log('ERROR', e);
    }
}

export function* getNumbersOpertorType(action) {
    try{
        yield put({
            type: NUMBERS_OPERATOR_LOADING,
        })
        const data = yield call(postDataWithToken, GET_TRACKING_OPERATORS_URL, action.params);
        yield put({
            type: NUMBERS_OPERATOR_TYPE,
            data: (data && data.data) ? data.data : {}
        })
        if (action.onSuccess) {
            action.onSuccess(data.data);
        }
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        yield put({
            type: NUMBERS_OPERATOR_TYPE,
            data: (e && e.data) ? e.data : {}
        })
        if (action.onSuccess) {
            action.onSuccess({});
        }
    }
}

export function* getLocationType(action) {
    try{
        const data = yield call(getDataWithToken, GET_HAWKEYE_FILTERS);
        yield put({
            type: SAVE_LOCATION_TYPE_FILTER,
            data: data.data || {}
        })
        if (action.onSuccess) {
            action.onSuccess(data.data);
        }
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* createLocation(action) {
    try {
        // yield put({
        //     type: NUMBERS_OPERATOR_LOADING,
        // })
        const data = yield call(postDataWithToken, CREATE_LOCATION_URL, action.data);
        if (action.onSuccess) {
            action.onSuccess(data.data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: data.mesage || "Location network added successfully"
        })
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getTripStatusCount(action) {
    try{
        const {
            boundType,
            serviceType,
            limit,
            offset,
            transporterSearch,
            plantSearch,
            group_id,
            itemsSearch,
            search,
            type,
            bound_type,
            truck_location_status,
            sub_type,
            trip_mode,
            transport_type,
            location_type_status,
            provider_id,
            tracking_type,
            vendor_type,
            search_field,
            branch_ids,
            provider_ids,
            service_type,
            untracked_message,
          } = action.params;
        const data = yield call(getDataWithToken, GET_TRIP_STATUS_COUNT_URL, {
            limit,
            offset,
            bound: boundType,
            service_type: serviceType,
            // transporter_search:transporterSearch,
            plant_search: plantSearch && Object.keys(plantSearch) ? plantSearch.city : plantSearch,
            branch_id: plantSearch && Object.keys(plantSearch) ? plantSearch.branch_id : '',
            branch_ids,
            provider_ids,
            group_id: group_id || (plantSearch && Object.keys(plantSearch) ? plantSearch.group_id : ''),
            item_search: itemsSearch,
            search,
            bound_type,
            type,
            type: truck_location_status,
            location_type_status,
            sub_type: sub_type || '',
            trip_mode,
            transport_type,
            provider_id,
            tracking_type,
            vendor_type,
            search_field,
            untracked_message
        });
        yield put ({
           type: SAVE_TRIP_STATUS_COUNT,
           data: data.data || []
        })
    } catch(e) {
        yield put ({
            type: SAVE_TRIP_STATUS_COUNT,
            data: []
         })
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* downloadTrip(action) {
    try {
        yield put({ type: CLEAR_DOWNLOAD_LOG });
        const {
            source_location,
            dest_location,
            status_text,
            group_id,
            to_date_search,
            from_date_search,
            di_time,
            interval,
            boundType,
            serviceType,
            limit,
            offset,
            transporterSearch,
            plantSearch,
            itemsSearch,
            search,
            type,
            bound_type,
            truck_location_status,
            sub_type,
            trip_mode,
            transport_type,
            location_type_status,
            download,
            search_field,
            untracked_message,
          } = action.params;
        const params = {
            limit,
            offset,
            interval,
            bound: boundType,
            service_type: serviceType,
            transporter_search:transporterSearch,
            plant_search: plantSearch && Object.keys(plantSearch) ? plantSearch.city : plantSearch,
            branch_id: plantSearch && Object.keys(plantSearch) ? plantSearch.branch_id : '',
            group_id: group_id || (plantSearch && Object.keys(plantSearch) ? plantSearch.group_id : ''),
            item_search: itemsSearch,
            search,
            bound_type,
            type,
            type: truck_location_status,
            location_type_status: location_type_status,
            sub_type: sub_type || '',
            trip_mode,
            transport_type,
            download,
            source_location,
            dest_location,
            status_text,
            to_date_search,
            from_date_search,
            di_time,
            search_field,
            untracked_message
        };
        const data = yield call(dowloadWithToken, GET_TRIPS_URL, 'trips.xlsx', params);
        yield put({
            type: DOWLOAD_LOCATION_LOGS_SUCCESS,
        });
    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getDONoBasedOnLocation(action) {
    try{
        // const data = yield call(postDataWithToken, `${GET_DO_NO_BASED_ON_FROM_LOC_URL}?${jsonToUrlParams(action.params)}`);
        const {data} = yield call(axios.post, GET_DO_NO_BASED_ON_FROM_LOC_URL, '',
        {
            baseURL: baseUrl,
            headers: {
                token: getToken(),
                'seeker-id': getCompanySeekerId()
            },
            params: action.params
        })
        if(action.onSuccess) {
            action.onSuccess(data.data || {})
        }
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getSchedulerDetails(action) {
    try {
        // yield put({
        //     type: CLEAR_DETAILED_LOG
        // });
        const data = yield call(getDataWithToken, GET_SCHEDULER_DASHBOARD_URL, action.data)
        if(data && data.data && data.data.locations) {
            const locations = (data.data.locations || []).map((loc)=> {
                return new Location(loc);
            });
            yield put({
                type: GET_DETAILED_LOG_SUCCESS,
                data: {
                    locations
                }
            });
        }
        yield put({
            type: GET_SCHEDULER_DASHBOARD_SUCCESS,
            data: data.data
        });
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
        yield put({
            type: GET_DETAILED_LOG_SUCCESS,
            data: {
                locations:[]
            }
        });
        console.log('ERROR', e);
    }
}

export function* getBranchLocationType(action) {
    try{
        const data = yield call(getDataWithToken, GET_BRANCH_URL);
        yield put({
            type: SAVE_BRANCH_TYPE_FILTER,
            data: data.data && data.data.locations || {}
        })
        yield put({
            type: SAVE_DI_TIME_FILTER,
            data: data.data && data.data.di_times || {}
        })
        if (action.onSuccess) {
            action.onSuccess(data.data && data.data.locations);
        }
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getSchedulerRouteDetails(action) {
    try {
        // yield put({
        //     type: CLEAR_DETAILED_LOG
        // });
        const data = yield call(getDataWithToken, GET_SCHEDULER_ROUTE_DETAILS_URL, action.data)
        
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
        yield put({
            type: GET_SCHEDULER_ROUTE_SUCCESS,
            data: data.data
        });
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
        yield put({
            type: GET_DETAILED_LOG_SUCCESS,
            data: {
                routeCode:[]
            }
        });
        console.log('ERROR', e);
    }
}

export function* downloadFOISTrackingTemp(action) {
    try {
        yield put({ type: CLEAR_DOWNLOAD_LOG });
        const filename = 'fois_template.xls';
        const data = yield call(dowloadWithToken, FOIS_DOWNLOAD_TEMPLATE_URL, filename);
        if (action.success) {
            action.success(data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: 'Downloading Template...'
        });
        yield put({
            type: DOWLOAD_LOCATION_LOGS_SUCCESS,
        });
    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* uploadFOISTracking(action) {
    try{
        const data = yield call(uploadFile, POST_FOIS_UPLOAD_URL, action.file);
        // yield put({
        //     type: GET_ADD_TRIP_TEMPLATE_SUCESS,
        //     data: {
        //         templateLink: ''
        //     }
        // });
        if(action.onSuccess) {
            action.onSuccess();
        }
        if (data.status == 200) {
            yield put({
                type: TOAST_SUCCESS,
                message: data.message || "Uploaded The Trips Successfully"
            })
        } else {
            yield put({
                type: TOAST_ERROR,
                message: data.message || "Option Unavailable"
            })
        }

    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getFOISDetails(action) {
    try {
        const filename = 'fois_template.xls';
        let data = []
        if(action.data.withData){
            data = yield call(dowloadWithToken, GET_FOIS_DATA_URL,filename,{
                limit: action.data.limit,
                offset: action.data.offset,
                fnr_no: action.data.fnr_no,
                type: action.data.type,
                start_date: action.data.start_date,
                end_date: action.data.end_date,
                from_station: action.data.from_station,
                to_station: action.data.to_station,
                download: action.data.withData ? 1 : 0
            });
        }
        else{
            data = yield call(getDataWithToken, GET_FOIS_DATA_URL,{
                limit: action.data.limit,
                offset: action.data.offset,
                fnr_no: action.data.fnr_no,
                type: action.data.type,
                start_date: action.data.start_date,
                end_date: action.data.end_date,
                from_station: action.data.from_station,
                to_station: action.data.to_station,
                download: action.data.withData ? 1 : 0
            })
        }
        
        if(action.onSuccess) {
            action.onSuccess(data);
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
        console.log('ERROR', e);
    }
}


export function* getFOISTrackingData(action) {
    try {
        let data = [];
        const url = `${GET_FOIS_TRACKING_URL}${action.fnr_number}`
        if(action.isDelete){
            data = yield call(deleteDataWithToken, url)
        } else{
            data = yield call(getDataWithToken, url)
        }
        if(action.onSuccess) {
            action.onSuccess(data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: data && data.data && data.data.message || "Record Deleted Successfully"
        })
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
        console.log('ERROR', e);
    }
}

export function* editFOISTrackingData(action) {
    try {
        let data = [];
        const url = `${GET_FOIS_TRACKING_URL}${action.fnr_number}`;
        data = yield call(putDataWithToken, url, action.data)
        if(action.onSuccess) {
            action.onSuccess(data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: data && data.data && data.data.message || "Record Deleted Successfully"
        })
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
        console.log('ERROR', e);
    }
}

export function* getFOISStationData(action) {
    try {
        const data = yield call(getDataWithToken, GET_FOIS_STATION_URL,{
            station_search: action.station_search
        })
        if(action.onSuccess) {
            action.onSuccess(data);
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
        console.log('ERROR', e);
    }
}

export function* postAdhocFNR(action) {
    try {
        const data = yield call(postDataWithToken, POST_ADHOC_FNR_URL, action.data);
        if (action.onSuccess) {
            action.onSuccess(data.data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: data.mesage || "FNR Tracking successfully"
        })
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getFOISDashboardCount(action) {
    try {
        const data = yield call(getDataWithToken, GET_FOIS_DASHBOARD_URL)
        if(action.onSuccess) {
            action.onSuccess(data);
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
        console.log('ERROR', e);
    }
}


export function* getFOISMovementData(action) {
    try {
        const data = yield call(getDataWithToken, GET_FOIS_MOVEMENT_TYPE_URL, action.data)
        // if(action.onSuccess) {
        //     action.onSuccess(data.data);
        // }
        yield put({
            type: GET_FOIS_MOVEMENT_TYPE_SUCCESS,
            data: data.data
        });
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* reminderEmail(action) {
    try {
        let data = [];
        if(action.isSave){
            data = yield call(postDataWithToken, REMINDER_EMAIL_URL, action.data);
        }
        else {
            data = yield call(getDataWithToken, REMINDER_EMAIL_URL, action.data);
        }
        yield put({
            type: GET_REMINDER_EMAIL_SUCCESS,
            data: data.data
        });
        if(action.onSuccess){
            action.onSuccess();
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}
export function* downloadMilestoneTemp(action) {
    try {
        yield put({ type: CLEAR_DOWNLOAD_LOG });
        const filename = 'milestone_template.xls';
        const data = yield call(dowloadWithToken, DOWNLOAD_MILESTONE_TEMP_URL, filename, (action.params || {}));
        if (action.success) {
            action.success(data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: 'Downloading Template...'
        });
        yield put({
            type: DOWLOAD_LOCATION_LOGS_SUCCESS,
        });
    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* generateDelayReasonTemplate(action) {
    try {
        yield put({ type: CLEAR_DOWNLOAD_LOG });
        const filename = 'milestone_template.xls';
        const data = yield call(dowloadWithToken, GENERATE_DELAY_REASON_TEMPLATE_URL, filename, (action.params || {}));
        if (action.success) {
            action.success(data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: 'Downloading Template...'
        });
        yield put({
            type: DOWLOAD_LOCATION_LOGS_SUCCESS,
        });
    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* uploadDelayReasons(action) {
    try{
        const data = yield call(uploadFile, UPLOAD_DELAY_REASONS_URL, action.file, (action.params || {}));
        if(action.onSuccess) {
            action.onSuccess();
        }
        if (data.status == 200) {
            yield put({
                type: TOAST_SUCCESS,
                message: data.message || "Uploaded The Trips Successfully"
            })
        } else {
            yield put({
                type: TOAST_ERROR,
                message: data.message || "Option Unavailable"
            })
        }

    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}


export function* uploadOrders(action) {
    try{
        const data = yield call(uploadFile, UPLOAD_ORDERS_URL, action.file, (action.params || {}));
        if(action.onSuccess) {
            action.onSuccess();
        }
        if (data.status == 200) {
            yield put({
                type: TOAST_SUCCESS,
                message: data.message || "Uploaded The Trips Successfully"
            })
        } else {
            yield put({
                type: TOAST_ERROR,
                message: data.message || "Option Unavailable"
            })
        }

    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getMilestoneOverviewDetails(action) {
    try {
        yield put({
            type: LOADING_START
        });
        let data = [];
        if (action.countData > action.lr_data_download_limit && action.params.download) {
            data = yield call(getDataWithToken, GET_MILESTONE_OVERVIEW_DETAILS_URL, action.params)
            yield put({
                type: TOAST_SUCCESS,
                message: data.message || "Success, please check your mail after some time",
            });
        }
        else if(action.params.download && action.countData < action.lr_data_download_limit){
            yield put({ type: CLEAR_DOWNLOAD_LOG });
            const filename = 'milestone_details.xls';
            data = yield call(dowloadWithToken, GET_MILESTONE_OVERVIEW_DETAILS_URL, filename, action.params);
        } else {
            data = yield call(getDataWithToken, GET_MILESTONE_OVERVIEW_DETAILS_URL, action.params)
        }
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
        yield put({
            type: GET_MILESTONE_OVERVIEW_SUCCESS,
            data: data.data
        });
    }catch(e) {
        yield put({
            type: LOADING_END
        });
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getMilestoneLrCountDetails(action) {
    try {
        yield put({
            type: LOADING_START
        });
        let data = [];
        if (action.countData > action.lr_data_download_limit && action.params.download) {
            data = yield call(getDataWithToken, GET_MILESTONE_OVERVIEW_DETAILS_URL, action.params)
            yield put({
                type: TOAST_SUCCESS,
                message: data.message || "Success, please check your mail after some time",
            });
        }
        else if(action.params.download && action.countData < action.lr_data_download_limit){
            yield put({ type: CLEAR_DOWNLOAD_LOG });
            const filename = 'milestone_details.xls';
            data = yield call(dowloadWithToken, GET_MILESTONE_LR_COUNT_DETAILS_URL, filename, action.params);
        } else {
            data = yield call(getDataWithToken, GET_MILESTONE_LR_COUNT_DETAILS_URL, action.params)
        }
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
        yield put({
            type: GET_MILESTONE_OVERVIEW_SUCCESS,
            data: data.data
        });
    }catch(e) {
        yield put({
            type: LOADING_END
        });
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getAuditListing(action) {
    try {
        yield put({
            type: LOADING_START
        });
        let data = [];
        if(action.params.download){
            yield put({ type: CLEAR_DOWNLOAD_LOG });
            const filename = 'audit_details.xls';
            data = yield call(dowloadWithToken, GET_AUDIT_LISTING_URL, filename, action.params);
        }else {
            data = yield call(getDataWithToken, GET_AUDIT_LISTING_URL, action.params)
        }
        if (action.onSuccess) {
            action.onSuccess(data.data);
        }
        yield put({
            type: GET_AUDIT_LISTING_SUCCESS,
            data: data.data
        });
    } catch (e) {
        yield put({
            type: LOADING_END
        });
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getAuditListCount(action) {
    try {
        yield put({
            type: LOADING_START
        });
        let data = [];
        if(action.params.download){
            yield put({ type: CLEAR_DOWNLOAD_LOG });
            const filename = 'audit_details.xls';
            data = yield call(dowloadWithToken, GET_AUDIT_LIST_COUNT_URL, filename, action.params);
        }else {
            data = yield call(getDataWithToken, GET_AUDIT_LIST_COUNT_URL, action.params)
        }
        if (action.onSuccess) {
            action.onSuccess(data.data);
        }
        yield put({
            type: GET_AUDIT_LISTING_SUCCESS,
            data: data.data
        });
    } catch (e) {
        yield put({
            type: LOADING_END
        });
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

// export function* getMilestoneReservedLrList(action) {
//     debugger
//     try {
//         yield put({
//             type: LOADING_START
//         });
//         // let data = [];
       
//             data = yield call(getDataWithToken, GET_MILESTONE_RESERVED_LR_LIST, action.params)
//             yield put({
//                 type: GET_MILESTONE_RESERVED_LR_SUCCESS,
//                 data: data
//             });
//         if(action.onSuccess) {
//             action.onSuccess(data.data);
//         }
//         yield put({
//             type: GET_MILESTONE_RESERVED_LR_SUCCESS,
//             data: data.data
//         });
      
//     }catch(e) {
//         yield put({
//             type: LOADING_END
//         });
//         yield put({
//             type: TOAST_ERROR,
//             // message: (e && e.message) || "Oops! Something went wrong"
//         });
//     }
// }
export function* getMilestoneReservedLrList(action) {
    try {
        const data = yield call(getDataWithToken, GET_MILESTONE_RESERVED_LR_LIST,action.params)
        // if(action.onSuccess) {
        //     action.onSuccess(data.data);
        // }
        yield put({
            type: GET_MILESTONE_RESERVED_LR_SUCCESS,
            data: data.data
        });
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
        
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}
export function* getMilestoneDetails(action) {
    try {
        yield put({
            type: LOADING_START
        });
        const data = yield call(getDataWithToken, GET_MILESTONE_ORDER_DETAILS_URL, action.params)
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
        yield put({
            type: GET_MILESTONE_ORDER_SUCCESS,
            data: data.data
        });
    }catch(e) {
        yield put({
            type: LOADING_END
        });
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getAuditDetails(action) {
    try {
        yield put({
            type: LOADING_START
        });
        const data = yield call(getDataWithToken, GET_AUDIT_DETAILS_URL, action.params)
        if (action.onSuccess) {
            action.onSuccess(data.data);
        }
        yield put({
            type: GET_AUDIT_LISTING_SUCCESS,
            data: data.data
        });
    } catch (e) {
        yield put({
            type: LOADING_END
        });
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* completeAudit(action) {
    try {
        const data = yield call(postDataWithToken, COMPLETE_AUDIT_URL, action.data);
      
        if (data.status == 200) {
            yield put({
                type: TOAST_SUCCESS,
                message: data.message || "Uploaded The Trips Successfully"
            })
           
        } else {
            yield put({
                type: TOAST_ERROR,
                message: data.message || "Option Unavailable"
            })
        }
        if (action.onSuccess) {
            action.onSuccess();
        }

    } catch (e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getMilestoneDocketList(action) {
    try {
        const data = yield call(getDataWithToken, GET_DOCKET_NUMBER_LIST_URL, action.params)
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getVehicleRemarks(action) {
        try {
            const data = yield call(getDataWithToken, GET_VEHICLE_REMARKS_URL,{
                is_type_reject: action.is_type_reject
            })
            // if(action.onSuccess) {
            //     action.onSuccess(data.data);
            // }
            yield put({
                type: GET_VEHICLE_REMARKS_SUCCESS,
                data: data.data
            });
        }catch(e) {
            yield put({
                type: TOAST_ERROR,
                message: (e && e.message) || "Oops! Something went wrong"
            });
        }
    }
    
    export function* confirmRejectVehicle(action) {
        try{
            // yield put({
            //     type: CLEAR_TRIPS
            // });
            const data = yield call(putDataWithToken, UPDATE_TRACKING_PREFERENCES_URL, action.data);
            yield put({
                type: TOAST_SUCCESS,
                message: data.data ? data.data.message : "Vehicle Rejected"
            })
            yield put({
                type: GET_TRIPS,
                params: action.params
            })
        } catch(e) {
            yield put({
                type: TOAST_ERROR,
                message: (e && e.message) || "Oops! Something went wrong"
            })
            
        }
     }
export function* downloadLSPMilestoneTemp(action) {
    try {
        yield put({ type: CLEAR_DOWNLOAD_LOG });
        const filename = 'lsp_milestone_template.xls';
        const data = yield call(dowloadWithToken, DOWNLOAD_LSP_TEMPLATE_URL, filename, (action.params || {}));
        if (action.success) {
            action.success(data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: 'Downloading Template...'
        });
        yield put({
            type: DOWLOAD_LOCATION_LOGS_SUCCESS,
        });
    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* uploadLSPDetails(action) {
    try{
        const data = yield call(uploadFile, UPLOAD_LSP_URL, action.file, (action.params || {}));
        if(action.onSuccess) {
            action.onSuccess();
        }
        if (data.status == 200) {
            yield put({
                type: TOAST_SUCCESS,
                message: data.message || "Uploaded The Trips Successfully"
            })
        } else {
            yield put({
                type: TOAST_ERROR,
                message: data.message || "Option Unavailable"
            })
        }

    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* uploadPODDetails(action) {
    try{
        const data = yield call(uploadFile, POD_UPLOAD_URL, action.file, action.data);
        if(action.onSuccess) {
            action.onSuccess();
        }
        if (data.status == 200) {
            yield put({
                type: TOAST_SUCCESS,
                message: data.message || "Uploaded The Trips Successfully"
            })
        } else {
            yield put({
                type: TOAST_ERROR,
                message: data.message || "Option Unavailable"
            })
        }

    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getServiceProvidersList(action) {
    try {
        const data = yield call(getDataWithToken, GET_SERVICE_PROVIDERS_URL, action.params)
        yield put({
            type: GET_SERVICE_PROVIDER_SUCCESS,
            data: data.data
        });
        if(action.onSuccess) {
           action.onSuccess();
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getMileStoneLocationsDropdownList(action) {
    try {
        const data = yield call(getDataWithToken, GET_MILESTONE_LOCATIONS_DROPDOWN_URL, action.params)
        yield put({
            type: SET_MILESTONE_LOCATIONS_DROPDOWN,
            data: data.data
        });
        if(action.onSuccess) {
            action.onSuccess();
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* realocateLsp(action) {
    try{
        const data = yield call(postDataWithToken, UPDATE_EXCEPTION_MILESTONE , action.data);
        yield put({
            type: UPDATE_EXCEPTION_MILESTONE,
            message: "Shared the Trip Details"
        })
        yield put({
            type: TOAST_SUCCESS,
            message: data.message || "Update The Trips Successfully"
        })
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

// export function* getMilestoneReservedLrList(action) {
//     debugger
//     try {
//         yield put({
//             type: LOADING_START
//         });
//         // let data = [];
       
//             data = yield call(getDataWithToken, GET_MILESTONE_RESERVED_LR_LIST, action.params)
//             yield put({
//                 type: GET_MILESTONE_RESERVED_LR_SUCCESS,
//                 data: data
//             });
//         if(action.onSuccess) {
//             action.onSuccess(data.data);
//         }
//         yield put({
//             type: GET_MILESTONE_RESERVED_LR_SUCCESS,
//             data: data.data
//         });
      
//     }catch(e) {
//         yield put({
//             type: LOADING_END
//         });
//         yield put({
//             type: TOAST_ERROR,
//             // message: (e && e.message) || "Oops! Something went wrong"
//         });
//     }
// }

export function* CancelPickupRequest(action) {
        try{
            const data = yield call(postDataWithToken, CANCEL_EXCEPTION, action.data);
            if(action.onSuccess) {
                action.onSuccess();
            }
            if (data.status == 200) {
                yield put({
                    type: TOAST_SUCCESS,
                    message: data.message || "Canceled Successfully"
                })
            } else {
                yield put({
                    type: TOAST_ERROR,
                    message: data.message || "Option Unavailable"
                })
            }
    
        } catch(e) {
           console.log(e);
            yield put({
                type: TOAST_ERROR,
                message: (e && e.message) || "Oops! Something went wrong"
            })
        }
    }

    
export function* movetoException(action) {
        try{
            const data = yield call(putDataWithToken, MOVE_TO_EXCEPTION_MILESTONE_UPDATE, action.data);
            if(action.onSuccess) {
                action.onSuccess();
            }
            if (data.status == 200) {
                yield put({
                    type: TOAST_SUCCESS,
                    message: data.message || "Canceled Successfully"
                })
            } else {
                yield put({
                    type: TOAST_ERROR,
                   message: data.message || "Option Unavailable"
                })
            }
    
        } catch(e) {
           console.log(e);
            yield put({
                type: TOAST_ERROR,
                message: (e && e.message) || "Oops! Something went wrong"
            })
        }
    }
    export function* getReasonsList(action) {
        try {
            const data = yield call(getDataWithToken, GET_REASONS, action.params)
            yield put({
                type: GET_REASONS_SUCCESS,
                data: data.data
            });
            if(action.onSuccess) {
                action.onSuccess(data.data);
            }
        }catch(e) {
            yield put({
                type: TOAST_ERROR,
                message: (e && e.message) || "Oops! Something went wrong"
            });
        }
    }
export function* successToast(action) {
    try {
        yield put({
            type: TOAST_SUCCESS,
            message: action.message || "Update The Trips Successfully"
        })
    } catch (e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
    }
}

export function* getMovementType(action) {
    try {
        const data = yield call(getDataWithToken, GET_MOVEMENT_TYPE_URL, action.params)
        yield put({
            type: GET_MOVEMENT_TYPE_DATA,
            data: data.data
        });
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
       
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getShortageType(action) {
    try {
        const data = yield call(getDataWithToken, GET_TYPE_OF_SHORTAGES_URL, action.params)
        yield put({
            type: GET_SHORTAGE_TYPE_DATA,
            data: data.data
        });
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
       
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getVehicleDetails(action) {
    try {
        const data = yield call(getDataWithToken, GET_VEHICLE_DETAILS_URL, action.params)
        yield put({
            type: GET_VEHICLE_LIST,
            data: data.data
        });
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
       
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getMultimodalTrackingDetails(action) {
    try {
        yield put({
            type: CLEAR_TRACKING_DETAIL
        })
        let param = {
            fnr_no: action.fnr_no,
        }
        const data = yield call(getDataWithToken, GET_MULTIMODAL_TRACKING_DETAILS_URL, param)
        const multimodaltrackingDetail = new Tracking(data.data);
        yield put({
            type: GET_MULTIMODAL_TRACKING_DETAILS_SUCCESS,
            data: {
                multimodaltrackingDetail
            }
        });
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        })
        yield put({
            type: GET_MULTIMODAL_TRACKING_DETAILS_SUCCESS,
            data: {
                multimodaltrackingDetail: new Tracking({})
            }
        });
        console.log('ERROR', e);
    }
}

export function* getTripConsigneeDropdownList(action) {
    try {
        const data = yield call(getDataWithToken, GET_TRIP_CONSIGNEE_DROPDOWN_URL, action.params)
        yield put({
            type: SET_TRIP_CONSIGNEE_DROPDOWN,
            data: data.data
        });
        if(action.onSuccess) {
            action.onSuccess();
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getTripPlantsAndDepotDropdownList(action) {
    try {
        const data = yield call(getDataWithToken, GET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN_URL, action.params)
        yield put({
            type: SET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN,
            data: data.data
        });
        if(action.onSuccess) {
            action.onSuccess();
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}
export function* getTripTransportersDropdownList(action) {
    try {
        const data = yield call(getDataWithToken, GET_TRIP_TRANSPORTERS_DROPDOWN_URL, action.params)
        yield put({
            type: SET_TRIP_TRANSPORTERS_DROPDOWN,
            data: data.data
        });
        if(action.onSuccess) {
            action.onSuccess();
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* downloadPickupRequestLogs(action) {
    try {
        yield put({ type: CLEAR_DOWNLOAD_LOG });
        const filename = `PickupRequestLogs_${action.lr_number}.xls`;
        const data = yield call(dowloadWithToken, GET_BOOKING_ORDER_LOGS_URL, filename, {
            lr_number: action.lr_number
        })
        if (action.success) {
            action.success(data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: 'Downloading Location Logs...'
        });
        yield put({
            type: SET_BOOKING_ORDER_LOGS,
        });
    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getApiLogTypesList(action) {
    try {
        const data = yield call(getDataWithToken, GET_API_LOG_TYPES_URL, action.params)
        yield put({
            type: SET_API_LOG_TYPES,
            data: data.data
        });
        if(action.onSuccess) {
            action.onSuccess();
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getApiLogDetails(action) {
    try {
        const data = yield call(getDataWithToken, GET_LOG_DETAILS_URL, action.params)
        yield put({
            type: SET_LOG_DETAILS,
            data: data.data
        });
        if(action.onSuccess) {
            action.onSuccess();
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getBookingOrderFailedDownlaods(action) {
  try {
    yield put({
      type: LOADING_START,
    });
    let data = [];
     
      yield put({ type: CLEAR_DOWNLOAD_LOG });
      const filename = "booking Order Failed.xls";
      data = yield call(
        dowloadWithToken,
        GET_BOOKING_ORDER_FAILED_DOWNLOADS_URL,
        filename,
        action.params
      );

    if (action.onSuccess) {
      action.onSuccess(data.data);
    }
    yield put({
      type: GET_MILESTONE_OVERVIEW_SUCCESS,
      data: data.data,
    });
  } catch (e) {
    yield put({
      type: LOADING_END,
    });
    yield put({
      type: TOAST_ERROR,
      message: (e && e.message) || "Oops! Something went wrong",
    });
  }
}

export function* getFastTagDetails(action) {
    try {
        let param = {
            trip_id: action.tripId
        }
        const data = yield call(getDataWithToken, GET_FAST_TAG_ROUTES_URL, param)
        yield put({
            type: SET_FAST_TAG_ROUTES,
            data: data.data
        });
        if(action.onSuccess) {
            action.onSuccess();
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}
export function* downloadFasttagLogs(action) {
    try {
        yield put({ type: CLEAR_DOWNLOAD_LOG });
        const filename = `fastTag${action.data.trip_id}.xls`;
        const data = yield call(dowloadWithToken, DOWNLOAD_FASTTAG_DETAILS_URL, filename, {
            trip_id: action.data.trip_id
        })
        if (action.success) {
            action.success(data.data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: 'Downloading FastTag Logs...'
        });
        yield put({
            type: SET_FAST_TAG_LOGS,
            data: data.data
        });
    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getTrackedLogDetails(action) {
    try {
        const data = yield call(getDataWithToken, GET_TRACKED_LOG_DETAILS_URL, action.params)
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* downloadPODUploadedFiles(action) {
    try {
        yield put({ type: CLEAR_DOWNLOAD_LOG });
        const data = yield call(getDataWithToken, DOWNLOAD_EXCEL_URL, (action.params || {}));
        if (action.onSuccess) {
            action.onSuccess(data.data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: 'Downloading...'
        });
        yield put({
            type: DOWLOAD_LOCATION_LOGS_SUCCESS,
        });
    } catch(e) {
        console.log(e);
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getUntrackedLogDetails(action) {
    try {
        const data = yield call(getDataWithToken, GET_UNTRACKED_LOG_DETAILS_URL, action.params)
        if (action.onSuccess) {
            action.onSuccess(data.data);
        }

    } catch (e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}

export function* getChannelTypesList(action) {
    try {
        const data = yield call(getDataWithToken, GET_CHANNEL_TYPES_URL, action.params)

        yield put({
            type: GET_CHANNEL_TYPE_SUCCESS,
            data: data.data
        });
        
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
    }catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: (e && e.message) || "Oops! Something went wrong"
        });
    }
}
