import { call, put } from 'redux-saga/effects';
import {
    postDataWithoutToken,
    SIGN_IN_URL,
    SIGN_OUT_URL,
    postLoginWithoutToken,
    CHANGE_PASSWORD_URL,
    FORGOT_PASSWORD_URL,
    REQUEST_OTP_URL,
    OTP_SIGNIN_URL,
    postDataWithToken,
    SSO_LOGIN_RESPONSE_URL,
    ssoGetDataWithToken,
    SSO_USER_VALIDATE, 
    ssoPostDataWithToken,
    SSO_DELETE_TOKEN_URL, 
    postSSODelete,
    SSO_CONFIG_URL,
    RESET_PASSWORD_URL,
    loginBaseUrl,
    getDataWithoutToken,
    postDataWithoutTokenV1,
    ssoPostDataWithTokenForResetOrChangePassword
} from 'Utils/api';
import {
    SIGN_IN_SUBMITTING,
    SIGN_IN_ERROR,
    TOAST_ERROR,
    TOAST_SUCCESS,
    DATA_LOADING,
    CLEAR_DATA_LOAD,
    REQUEST_OTP_SUBMITTING,
    REQUEST_OTP_SUCCESS,
    STORE_SSO_CONFIG_DATA,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_SUBMITTING
} from 'Constants/actionConstants';
import { storeToken, storeCompanyType, storeUserType, storeUser, deleteToken, storeChangeToken } from 'Utils/storage';
import { SEEKER, USER_TYPE, GENERIC_TOKEN } from 'Constants/commonConstants';
import { getDefaultPath } from 'Utils/common';


export function* signin(action) {
    const { email, password, device_type, companyType,module_id ,force_logout,
        captcha_value
    } = action.data;
    try{
        yield put({
            type : SIGN_IN_SUBMITTING
        })

        const payload = {
            email,
            password,
            device_type,
            company_type: companyType,
            module_id:3,
            force_logout,
            captcha_value
        };
        console.log(loginBaseUrl, '++++++++++++++');
        const data = yield call(ssoPostDataWithToken, SIGN_IN_URL, payload, loginBaseUrl);
        const userType = data.data.user_type;
        const userName = data.data.name;
        if(data.data.is_password_expired) {
            location.href = `/changePassword`;
            storeChangeToken(data.data.token);
        } else {
            storeToken(data.data.token);
            storeUser(data.data);
            storeCompanyType(companyType);
            storeUserType(userType);
            localStorage.setItem('teg-component', true);
            window.location.href = (companyType === SEEKER) ? (userName == "Pantaloons" ? "/milestone" : "/dashboard") : 
            // window.location.href = (companyType === SEEKER) ? getDefaultPath(companyType,userName) : 
            (data.data.vims_tabs && data.data.vims_tabs.includes('trips') ? "/trips" :
            (data.data.vims_tabs && data.data.vims_tabs.includes('milestone') ? "/milestone" : '/trips'));
        }
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: SIGN_IN_ERROR,
            error: ''
        })
        if(e && e.data && e.data == 1) {
            action.onError(e.data);
        }
        if(action.onError){
            action.onError(e.data);
        }
        yield put({
            type: TOAST_ERROR,
            message: e && e.message?e.message:"Oops! Something went wrong"
        })
    }
}

export function* signout(action) {
    try {
        const data = yield call(ssoPostDataWithToken, SIGN_OUT_URL, action.data, loginBaseUrl);
        if(action.onSuccess) {
            action.onSuccess();
        }
        yield put({
            type: TOAST_SUCCESS,
            message: "Logged out successfully"
        })
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: e && e.message ? e.message : "Oops! Something went wrong"
        })
    }
}

export function* changePassword(action) {
    try {
        // const data = yield call(ssoPostDataWithToken, CHANGE_PASSWORD_URL, action.data, loginBaseUrl);
        const url = action.isReset ? RESET_PASSWORD_URL : CHANGE_PASSWORD_URL ;
        const data = yield call(ssoPostDataWithTokenForResetOrChangePassword, url, action.data, (action.isReset ? action.token : null));
        if(action.onSuccess) {
            action.onSuccess(data);
        }
        yield put({
            type: TOAST_SUCCESS,
            message: data.message || "Successfully Password has been changed"
        })
    } catch(e) {
        yield put({
            type: TOAST_ERROR,
            message: e && e.message?e.message:"Oops! Something went wrong"
        })
    }
}

export function* forgotPassword(action) {
    try {
        yield put({ type: DATA_LOADING });
        const data = yield call(postDataWithoutTokenV1, FORGOT_PASSWORD_URL, action.data, loginBaseUrl);
        if (action.onSuccess) {
            action.onSuccess();
        }
        yield put({
            type: TOAST_SUCCESS,
            message: data.message || "Success"
        })
        yield put({ type: CLEAR_DATA_LOAD });
    } catch(e) {
        yield put({ type: CLEAR_DATA_LOAD });
        yield put({
            type: TOAST_ERROR,
            message: e && e.message ? e.message : "Oops! Something went wrong"
        })
    }
}
export function* requestOTP(action) {
    try{
        yield put({
            type : DATA_LOADING
        });
        const data = yield call(ssoPostDataWithToken, REQUEST_OTP_URL, action.data, loginBaseUrl);
        yield put({
            type: TOAST_SUCCESS,
            message: (data && data.message) || "OTP Sent Successfully",
        })
        if(action.onSuccess) {
            action.onSuccess(data.data);
        }
        yield put({
            type: DATA_LOADING,
            error: ''
        })
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: DATA_LOADING,
            error: ''
        })
        yield put({
            type: TOAST_ERROR,
            message: e && e.message ? e.message : "Oops! Something went wrong"
        })
        if(e && e.message) {
            action.onError(e.message);
        }
        
    }
}

export function* loginWithOTP(action) {
    const { contact_number, otp_number, device_type, companyType,
    force_logout,module_id,captcha_value,email
    } = action.data;
    try{
        yield put({
            type : SIGN_IN_SUBMITTING
        })
        const payload = {
            contact_number,
            otp_number,
            device_type,
            company_type: companyType,
            module_id: 3,
            email:email,
            force_logout,
            captcha_value
        };
        const data = yield call(ssoPostDataWithToken, OTP_SIGNIN_URL, payload, loginBaseUrl);
        const userType = data.data.user_type;

        storeToken(data.data.token);
        storeUser(data.data);
        storeCompanyType(companyType);
        storeUserType(userType);
        localStorage.setItem('teg-component', true);
        window.location.href = (companyType === SEEKER) ? "/dashboard" : "/trips";
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: SIGN_IN_ERROR,
            error: ''
        })
        if(e && e.data && e.data == 1) {
            action.onError(e.data);
        }
        if(action.onError){
            action.onError(e.data);
        }
        yield put({
            type: TOAST_ERROR,
            message: e && e.message?e.message:"Oops! Something went wrong"
        })
    }
}
export function* loginWithSSO(action) {
    try{
        const data = yield call(ssoGetDataWithToken, SSO_LOGIN_RESPONSE_URL, {});
        const userType = data.data.user_type;

        storeToken(data.data.token);
        storeUser(data.data);
        storeCompanyType(data.data.company_type);
        storeUserType(userType);
        if(action.onSuccess){
            action.onSuccess();
        }
        window.location.href = "/dashboard";
        localStorage.setItem('sso-session', true);
        localStorage.setItem('teg-component', false);
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: SIGN_IN_ERROR,
            error: ''
        })
        yield put({
            type: TOAST_ERROR,
            message: e && e.message?e.message:"Oops! Something went wrong"
        })
    }
}

export function* ssoConfig(action) {
    try{
        const data = yield call(ssoGetDataWithToken, SSO_CONFIG_URL, action.params, GENERIC_TOKEN);
        if (data && data.data) {
            yield put({
                type: STORE_SSO_CONFIG_DATA,
                ssoConfigData: data.data
            });
        }
    } catch(e) {
        console.log('ERROR', e);
    }
}

export function* ssoUserValidate(action) {
    try{
        const data = yield call(ssoPostDataWithToken, SSO_USER_VALIDATE, action.data, GENERIC_TOKEN);
        if(action.onSuccess){
            action.onSuccess(data);
        }
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: e && e.message?e.message:"Oops! Something Went Wrong"
        })
    }
}

export function* ssoDeleteToken(action) {
    try{
        const data = yield call (postSSODelete, SSO_DELETE_TOKEN_URL, action.params);
        // deleteToken();
    } catch(e){
        console.log('ERROR', e);
        yield put({
            type: TOAST_ERROR,
            message: e && e.message?e.message:"Oops! Something Went Wrong"
        })
    }
}

export function* validateForgotPassword(action) {
    try{
        yield put({
            type : FORGOT_PASSWORD_SUBMITTING
        })
        const data = yield call(getDataWithoutToken, FORGOT_PASSWORD_URL, action.data);
        yield put({
            type: TOAST_SUCCESS,
            message: (data && data.message) || "Password Reset Successfully"
        })
        yield put({
            type: FORGOT_PASSWORD_SUCCESS,
            error: ''
        })
        if(action.onSuccess){
            action.onSuccess(data.data)
        }
    } catch(e) {
        console.log('ERROR', e);
        yield put({
            type: FORGOT_PASSWORD_SUCCESS,
            error: ''
        })
        yield put({
            type: TOAST_ERROR,
            message: e && e.message?e.message:"Oops! Something Went Wrong"
        })
        if(action.onError){
            action.onError()
        }
    }
}