import React, { Component } from 'react'
import "./ResetPassword.scss"
import Logo from "Components/Logo/index";
import Tabs from "Components/Tabs/index";
import { ReactComponent as PasswordVisible } from "./passwordvisible.svg";
import { ReactComponent as PasswordNotVisible } from "./passwordNotVisible.svg";
import Button from "./Button";
import Rectangle from "./Rectangle";
import "./Rectangle.scss";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ActionLoader from 'Components/ActionLoader';
import * as Recaptcha from 'react-recaptcha';
import { getSecureRandomKey } from 'Utils/index';
import { CHANGE_PASSWORD, VALIDATE_FORGOT_PASSWORD } from 'Constants/actionConstants';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                newPassword: "",
                confirmPassword: "",
                expiryToken: window.location,
                errorMessage: "",
                actualCaptcha: '',
                captchaEntered: '',
                isVerified: false,
                showTag: false,
                email: '',
                validMail: false,
                reCaptchaKey: 1,
                captcha_value: '',
                isNewPasswordVisible: false,
                isConfirmPasswordVisible: false,
                newPasswordFocused: false,
                confirmPasswordFocused: false
            }
        }
    }

    componentDidMount() {
        setTimeout(function () { 
            this.setState({ showTag: true })
        }.bind(this), 1000)
        // this.validateMail()
    }

    validateMail = () => {
        const { dispatch, history } = this.props;
        let token = window.location.search && window.location.search.split('?')
        if (!token[1].includes('newPassword')) {
            dispatch({
                type: VALIDATE_FORGOT_PASSWORD,
                data: {
                    mail_token: token[1]
                },
                onSuccess: (userData) => {
                    this.setState({
                        email: userData.email,
                        company_type: userData.company_type,
                        validMail: true
                    })
                },
                onError: () => {
                    history.push('/')
                }
            })
        }
    }

    verifyCallback = (response) => {
        const { dispatch } = this.props;
        // dispatch({
        //   type: VALIDATE_RECAPTCHA,
        //   data: {
        //     captcha_value: response
        //   },
        //   onSuccess: (successData) => {
        //     if(successData && successData.success){
        //       this.setState({
        //         isVerified:true
        //       });
        //       this.setState({
        //         errorMessage:""
        //       });
        //     }
        //   }
        // })

        this.setState((prevState) => {
            const updatedState = { ...prevState };
            updatedState.captcha_value = response
            updatedState.isVerified = true
            return updatedState
        })
    }

    callback = () => {
        console.log('Done!!!!');
    };

    onExpire = () => {
        this.setState({
            isVerified: false
        });
    };

    onChange = (e) => {
        const { data } = this.state;
        data[e.target.name] = e.target.value
        this.setState({ data })
    }

    resetpassword = (e) => {
        const { dispatch, history } = this.props;
        const { data, confirmPassword, company_type, email,
            captcha_value
        } = this.state;
        e.preventDefault(e);
        let token = window.location.search && window.location.search.split('?')
        dispatch({
            type: CHANGE_PASSWORD,
            data: {
                // reset_token:token[1],
                // email,
                new_password: data.newPassword,
                confirm_new_password: data.confirmPassword,
                // company_type,
                module_id: 3,
                captcha_value
            },
            token: ((token[1] || '').split('=') || [])[1] || '',
            isReset: true,
            onSuccess: ((data) => {
                const path = (data.status === 200) ? '/' : '/forgot-password';
                history.push(path);
            }),
            onError: (errorData) => {
                if (errorData.data && errorData.data.validation_errors) {
                    this.setState({ errorMessage: errorData.data.validation_errors })
                }

            }
        })

        this.setState((prevState) => {
            const updatedState = { ...prevState };
            updatedState.isVerified = false
            updatedState.reCaptchaKey = getSecureRandomKey()
            return updatedState
        })
    }

    toggleisNewPasswordVisibility = () => {
        this.setState((prevState) => ({
            isNewPasswordVisible: !prevState.isNewPasswordVisible,
        }));
    };
    toggleisConfirmPasswordVisibility = () => {
        this.setState((prevState) => ({
            isConfirmPasswordVisible: !prevState.isConfirmPasswordVisible,
        }));
    };

  render() {
      const { 
        isVerified, 
        newPassword, 
        confirmPassword, 
        errorMessage, 
        data,
        reCaptchaKey,
        isNewPasswordVisible,
        isConfirmPasswordVisible
      } = this.state;
    //   const isDisabled = !(isVerified && data.newPassword && data.confirmPassword)
      const isDisabled = !(data.newPassword && data.confirmPassword)
    return (
        <div className='resetPasswordMain'>
            <form onSubmit={this.resetpassword}>
            {/* <Rectangle className={"otpRectangleTopL"} /> */}
            {/* <Rectangle className={"otpRectangleTopS"} /> */}
                <div className="resetPasswordCardMain">
                    <div className="resetPasswordCard">
                        <div className="resetPasswordLogo">
                            <Logo className="resetPasswordLogoImage" />
                        </div>
                        <div className="resetPasswordPasswordMain">
                            <p className="resetPasswordPasswordLable">New Password</p>
                            <div className={`resetPasswordPasswordBox ${this.state.newPasswordFocused ? 'box-focused' : ''}`}>
                                <input
                                    type={isNewPasswordVisible ? "text" : "password"}
                                    value={this.state.newPassword}
                                    onChange={this.onChange}
                                    className="resetPasswordPasswordInput"
                                    name="newPassword"
                                    maxLength={16}
                                    onFocus={() => this.setState({ newPasswordFocused: true })}
                                    onBlur={() => this.setState({ newPasswordFocused: false })}
                                ></input>
                                <div
                                    className="resetPasswordPasswordIcon"
                                    // onClick={this.toggleisNewPasswordVisibility}
                                    onClick={() => {
                                        this.toggleisNewPasswordVisibility();
                                        this.setState({ newPasswordFocused: true })
                                    }}
                                >
                                    {isNewPasswordVisible ? (
                                        <PasswordVisible />
                                    ) : (
                                        <PasswordNotVisible />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="resetPasswordPasswordMain">
                            <p className="resetPasswordPasswordLable">Confirm New Password</p>
                            <div className={`resetPasswordPasswordBox ${this.state.confirmPasswordFocused ? 'box-focused' : ''}`}>
                                <input
                                type={isConfirmPasswordVisible ? "text" : "password"}
                                    value={this.state.confirmPassword}
                                    onChange={this.onChange}
                                    className="resetPasswordPasswordInput"
                                    name="confirmPassword"
                                    maxLength={16}
                                    onFocus={() => this.setState({ confirmPasswordFocused: true })}
                                    onBlur={() => this.setState({ confirmPasswordFocused: false })}
                                ></input>
                                <div
                                    className="resetPasswordPasswordIcon"
                                    // onClick={this.toggleisConfirmPasswordVisibility}
                                    onClick={() => {
                                        this.toggleisConfirmPasswordVisibility();
                                        this.setState({ confirmPasswordFocused: true })
                                    }}
                                >
                                    {isConfirmPasswordVisible ? (
                                        <PasswordVisible />
                                    ) : (
                                        <PasswordNotVisible />
                                    )}
                                </div>
                            </div>
                        </div>
                    {/* {this.state.showTag && <Recaptcha
                        sitekey="6LcHtbwUAAAAAKkjbo7nNh9ajzF5GEHk9uC5skGG"
                        render="explicit"
                        verifyCallback={this.verifyCallback}
                        onloadCallback={this.callback}
                        expiredCallback={this.onExpire}
                        key={reCaptchaKey}
                    />} */}
                    {errorMessage && errorMessage.length && errorMessage.map((error) => {
                        return (
                            <div className="resetPasswordErrorBox">{error}</div>
                        )
                    })}
                        <div className="resetPasswordButtonBox">
                            <Button 
                             Name={"Submit"} 
                             type="submit"
                            //  className={"resetPasswordButton"} 
                             className={`resetPasswordButton ${!isDisabled ? 'isnotDisable' : ''}`}
                             disabled={isDisabled}
                            />
                        </div>
                    </div>
                </div>
            {/* <Rectangle className={"otpRectangleBottomL"} /> */}
            {/* <Rectangle className={"otpRectangleBottomS"} /> */}
            </form>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        isDataLoading: state.authentication.isDataLoading || false,
    }
};

export default connect(mapStateToProps)(withRouter(ResetPassword))

